import { forEach, get, isNil, has, defaultTo, set, omitBy, mapValues } from 'lodash';
import {
  ClientActionHandlersConfig,
  ClientActionHandler,
  ConfigurableGridConfigItem,
} from 'src/components/ConfigurableGrid/ConfigurableGrid.types';
import { ARRAY_EDITOR_COLUMN_ID_PREFIX } from 'src/utils/Domain/Constants';
import {
  CellEditingStartedEvent,
  ColDef,
  ColGroupDef,
  Column,
  GridApi,
  IRowNode,
  MenuItemDef,
} from '@ag-grid-community/core';
import { BasicItem } from 'src/types/Scope';
import { TenantConfigViewItem } from 'src/dao/tenantConfigClient';
import { ConfigurablePostAction, MassEditCoordinateMap } from 'src/services/configuration/codecs/viewdefns/general';
import { AsyncCellState } from '../ConfigurableGrid.types';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { EditCoordinates } from 'src/dao/pivotClient';

export function parseActions(config: ClientActionHandlersConfig): ClientActionHandler {
  const retObj: ClientActionHandler = {};

  forEach(config, (actionValue, _action) => {
    // at this point the name of the action doesn't really matter only the clientHandler name and value (string[])
    forEach(actionValue, (attributesToHandle, clientHandler) => {
      retObj[clientHandler] = attributesToHandle;
    });
  });

  return retObj;
}

export function isGroupColDef(colDef: ColDef | ColGroupDef): colDef is ColGroupDef {
  return has(colDef, 'groupId');
}

export function isGroupNode(node: IRowNode): boolean {
  return !isNil(node.allChildrenCount) && node.allChildrenCount > 0;
}
export function isNotGroupNode(node: IRowNode): boolean {
  return !isGroupNode(node);
}

function isRowNode(rn: unknown): rn is IRowNode {
  const typedRowNode = rn as IRowNode;
  return 'parent' in typedRowNode && 'data' in typedRowNode;
}

export function updateWithClientHandler(field: string, clientHandlers: ClientActionHandler | undefined): boolean {
  if (isNil(clientHandlers)) {
    return false;
  }

  let handleWithClientHandler = false;
  forEach(clientHandlers, (attributesToHandle, _clientHandler) => {
    if (attributesToHandle.indexOf(field) >= 0) {
      handleWithClientHandler = true;
    }
  });

  return handleWithClientHandler;
}

export function calculateColumnWidth(dataIndex: string): number {
  switch (dataIndex) {
    case 'popoverTrigger':
      return 60;
    case 'attribute:ccstylecolorcreatedate:id':
      return 215;
    default:
      return 200;
  }
}

export function resetAsyncValidationData(event: CellEditingStartedEvent) {
  const { node } = event;
  if (has(node.data, 'invalid:style')) {
    const resetData = { ...node.data };
    delete resetData['invalid:style'];
    node.setData(resetData);
  }
}

export const floorsetDataToDropdown = (floorsetData: BasicItem): TenantConfigViewItem => {
  return {
    ...floorsetData,
    dataIndex: floorsetData.id,
    text: floorsetData.name,
  };
};

export const parseFloorsetDropdownData = (obj: BasicItem[]) => {
  // Currently, floorset returns data that needs to be mutated (id => dataIndex and name => text) for subheader
  return obj.map(floorsetDataToDropdown);
};

/** Converts an action from ConfigurablePostAction shape to MenuItemDef shape */
export const formatConfigurableActionItem = (
  action: ConfigurablePostAction,
  actionHandler: (updateType: 'coarse' | 'granular', value: unknown, valueDataIndex: string) => void
): MenuItemDef => {
  const { name, icon, tooltip, updateType, value, valueDataIndex } = action;
  return {
    name,
    icon,
    tooltip,
    action: actionHandler.bind(null, updateType, value, valueDataIndex),
  };
};

export function refreshGridCells(
  gridApi: GridApi,
  rowNodes: IRowNode[],
  columns: (string | Column)[],
  options?: { forceRefresh: boolean }
) {
  const force = defaultTo(options?.forceRefresh, true);
  gridApi.refreshCells({
    rowNodes,
    columns,
    force,
  });
  gridApi.refreshHeader();
}

function setGroupNodeAsyncState(
  node: IRowNode,
  fields: string[],
  state: AsyncCellState,
  updateChildren: boolean
): void {
  fields.forEach((field) => {
    set(node, `data.asyncstate.${field}`, state);
  });
  if (updateChildren) {
    node.allLeafChildren.forEach((leafNode) => {
      fields.forEach((field) => {
        set(leafNode, `data.asyncstate.${field}`, state);
      });
    });
  }
}

function setRowNodeAsyncState(node: IRowNode, fields: string[], state: AsyncCellState, updateChildren: boolean): void {
  fields.forEach((field) => {
    set(node, `data.asyncstate.${field}`, state);
  });
  if (updateChildren) {
    node.childrenAfterFilter?.forEach((childNode) => {
      fields.forEach((field) => {
        set(childNode, `data.asyncstate.${field}`, state);
        childNode.setDataValue(`asyncstate.${field}`, state);
      });
    });
  }
}

function setRowNodesAsyncState(nodes: IRowNode[], fields: string[], state: AsyncCellState): void {
  fields.forEach((field) => {
    nodes.forEach((node) => {
      set(node, `data.asyncstate.${field}`, state);
    });
  });
}

function setItemAsyncState(
  item: BasicPivotItem,
  fields: string[],
  state: AsyncCellState,
  updateChildren: boolean
): void {
  fields.forEach((field) => {
    set(item, `asyncstate.${field}`, state);
  });
  if (updateChildren) {
    item.children.forEach((childItem) => {
      fields.forEach((field) => {
        set(childItem, `asyncstate.${field}`, state);
      });
    });
  }
}

export function updateNodeAsyncState(
  node: IRowNode | BasicPivotItem,
  field: string[],
  state: AsyncCellState,
  options?: { updateNodeChildren: boolean }
) {
  const updateChildren = defaultTo(options?.updateNodeChildren, false);
  if (isRowNode(node)) {
    if (isGroupNode(node)) {
      setGroupNodeAsyncState(node, field, state, updateChildren);
    } else {
      setRowNodeAsyncState(node, field, state, updateChildren);
    }
  } else {
    setItemAsyncState(node, field, state, updateChildren);
  }
}

export function updateAsyncState(nodes: IRowNode[], fields: string[], state: AsyncCellState) {
  setRowNodesAsyncState(nodes, fields, state);
}

export function isMultiNodeUpdate(nodes: IRowNode[]) {
  return nodes.length > 1;
}

export function isGroupNodeUpdate(nodes: IRowNode[]) {
  return nodes.length === 1 && isGroupNode(nodes[0]);
}

/**
 * Generate a set of coordinates,
 * filtering out nils for the set only having a subset of avail props.
 */
export function generateCoordinateValues(
  coordinateMap: MassEditCoordinateMap,
  dataItem: BasicPivotItem | IRowNode
): EditCoordinates {
  const item = isRowNode(dataItem) ? dataItem.data : dataItem;
  return omitBy(
    mapValues(coordinateMap, (v) => item[v]),
    isNil
  );
}

/**
 * Strips down the `dataItem` to a set of values based on the supplied function arguments.
 * `redecorateMap` takes precedence over `onlySendCoordinateMap` if both are configured.
 * If neither are configured, only UI generated data keys will be stripped including array editor data, asyncstate, and calc state data
 *
 * @param coordinates generated coordinate values based on `updateCoordinateMap` configuration
 * @param redecorateMap optional set of key/values that configurer can provide to make sure redecorate submits explicit data that is different than what is in the `updateCoordinateMap` configuration
 * @param onlySendCoordinateMap optional flag that configurer can set to only send values setup in the `updateCoordinateMap` configuration
 * @param dataItem item to retrieve data from for submitting to redecorate
 * @param leafIdProp
 * @returns BasicPivotItem
 */
export function filterItemForRedecorate(
  coordinates: EditCoordinates,
  redecorateMap: Record<string, string> | undefined,
  onlySendCoordinateMap: boolean,
  dataItem: BasicPivotItem,
  leafIdProp: string
): BasicPivotItem {
  if (redecorateMap) {
    const redecorateMapWithId = {
      ...redecorateMap,
      [leafIdProp]: leafIdProp, // leafIdProp is needed to merge item back into data after redecorate
    };

    const onlyRedecorateMapKeys = omitBy(
      mapValues(redecorateMapWithId, (v) => dataItem[v]),
      isNil
    ) as BasicPivotItem;

    return onlyRedecorateMapKeys;
  } else if (onlySendCoordinateMap) {
    return { ...coordinates } as BasicPivotItem;
  } else {
    // strip out array editor keys that were storing old values and preventing the header editor value from being applied after redecorate
    // also remove other custom ui keys

    const withoutLocalKeys = omitBy(dataItem, (_v, k) => {
      return k.includes(ARRAY_EDITOR_COLUMN_ID_PREFIX) || k.includes('asyncstate') || k.includes('__calc_editted');
    }) as BasicPivotItem;

    return withoutLocalKeys;
  }
}

export function getCellAsyncState(node: IRowNode, colInfo: { dataIndex: string }) {
  return get(node.data, `asyncstate.${colInfo.dataIndex}`, AsyncCellState.Idle);
}
