import { style, classes } from 'typestyle';
import { color, important, rem, px, rgba } from 'csx';
import { TEAL_PRIMARY } from 'src/utils/Style/Theme';
import acceptButton from 'src/components/AcceptButton/AcceptButton.styles';
import RejectButton from 'src/components/RejectButton/RejectButton.styles';
const gridTitleMargin = px(10);
const editableBackgroundColor = color('#edf7f6');
const disabledTextColor = color('#c1c1c1');
const colorHeaderRowHeight = 130;
const defaultRowHeight = 30;
const defaultGroupRowHeight = 40;
const validSizesRowHeight = 150;

const editableColumn = style({
  $debugName: 'editable-column',
  backgroundColor: editableBackgroundColor.toHexString(),
});

const editableCell = style({
  $debugName: 'editable-cell',
  color: TEAL_PRIMARY,
  justifyContent: 'center',
});

const headerCell = style({
  justifyContent: 'center',
});

const editableRow = style({
  $debugName: 'editable-row',
  backgroundColor: important(rgba(0, 168, 155, 0.2).toString()), // TEAL_PRIMARY with more opacity, important needed
  fontWeight: 'bold',
});

const cellDisabled = style({
  $debugName: 'StyleEditCellDisabled',
  cursor: 'not-allowed',
  color: disabledTextColor.toHexString(),
});

const notInLifecycle = style({
  $debugName: 'not-in-lifecycle',
  fontWeight: 600,
  letterSpacing: '1.2px',
  textTransform: 'uppercase',
  backgroundColor: '#F9F9F9',
  textAlign: 'center',
});

const sectionGrid = style({
  $debugName: 'StyleEditSectionContainer',
  display: 'flex',
  flex: 1,
  width: 'inherit',
  fontSize: '0.8rem',
  $nest: {
    '& > div:first-child': {
      flex: 'auto',
    },
    '.ag-root-wrapper': {
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      overflow: 'visible',
      boxSizing: 'border-box',
      $nest: {
        '&.ag-layout-normal': {
          height: '100 %',
        },
      },
    },
    '.ag-root-wrapper-body': {
      display: 'flex',
      flexDirection: 'row',
      $nest: {
        '&.ag-layout-normal': {
          flex: 1,
          overflow: 'hidden',
        },
      },
    },
    '.ag-root': {
      overflow: important('initial'),
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      $nest: {
        '&.ag-layout-normal, &.ag-layout-auto-height': {
          overflow: 'hidden',
          minWidth: '0px',
          flex: 1,
        },
        '&.ag-layout-normal': {
          height: '100%',
        },
      },
    },
    '.ag-body-horizontal-scroll-viewport': {
      // For unclear reasons, chrome windows miscalculates the size of the horizontal scrollbar,
      // and blocks clicks to the last cells of grids
      // because we don't use the ag-grid scrollbar in style edit (and suppressing it still has the issue),
      // we disable scrollbar events here
      pointerEvents: 'none',
    },
    // TODO: once custom group cell renderer is working, this should be removed
    // '.ag-body div[role="rowgroup"] div[role="row"] span.ag-group-value': {
    //   fontSize: rem(1.1),
    // },
    '.ag-header': {
      position: 'sticky',
      top: '135px',
      zIndex: 5,
      // match ag-grid's color
      borderBottom: `1px solid rgb(222, 225, 230)`,
      $nest: {
        '& .ag-pinned-left-header': {
          border: 'none',
        },
        '& .ag-pinned-right-header': {
          border: 'none',
        },
        '& .ag-header-viewport': {
          border: 'none',
        },
      },
    },
    [`.ag-row-focus:not(.ag-row-hover) .${editableCell}`]: {
      color: 'white',
    },
    '.ag-pinned-left-cols-container .ag-cell': {
      justifyContent: 'left',
    },
    '.ag-cell': {
      justifyContent: 'center',
    },
  },
});

const sectionGridTitle = style({
  width: 'calc(100% + 24px)',
  marginRight: gridTitleMargin,
  position: 'sticky',
  top: 225,
  fontSize: rem(1.1),
  background: 'white',
  zIndex: 10,
  display: 'inline-block',
  $nest: {
    '.leftTitleContainer': {
      position: 'sticky',
      left: 0,
      width: 200,
      background: 'white',
      paddingTop: 10,
    },
  },
});

const checkboxContainer = style({
  height: 40,
  width: '100%',
  margin: '0 auto',
  textAlign: 'center',
  padding: 8,
});
const iconButton = style({
  float: 'right',
  width: 22,
  height: 22,
  $nest: {
    '> span': {
      fontSize: 28,
    },
  },
  boxShadow: '0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%)',
  cursor: 'pointer',
  borderRadius: 4,
  minWidth: 0,
  border: 'none',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: '#f9f9f9',
});
const iconButtonCancel = classes(
  iconButton,
  RejectButton,
  style({
    background: '#f44336',
    marginLeft: 5,
  })
);
const iconButtonSave = classes(
  iconButton,
  acceptButton,
  style({
    background: '#4caf50',
  })
);

export {
  editableColumn,
  editableCell,
  editableRow,
  cellDisabled,
  notInLifecycle,
  sectionGrid,
  sectionGridTitle,
  checkboxContainer,
  iconButton,
  iconButtonCancel,
  iconButtonSave,
  colorHeaderRowHeight,
  defaultRowHeight,
  defaultGroupRowHeight,
  validSizesRowHeight,
  headerCell,
};
