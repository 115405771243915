import React, { FC } from 'react';
import { IHeaderComp, ICellRendererComp } from '@ag-grid-community/core';
import { isEmpty } from 'lodash';
import { getSwatchUrl } from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.utils';
import { PINKISH, GRAY } from 'src/utils/Style/Theme';

import { classes, style } from 'typestyle';

const colorHeaderStyle = style({
  display: 'flex',
  flexDirection: 'column',
  height: '100px',
  width: '100%',
  justifyContent: 'space-around',
  alignItems: 'center',
  // match ag-grid's color
  borderRight: `1px solid rgb(222, 225, 230)`,
  $nest: {
    '& > img': {
      width: '25px',
      height: '25px',
      border: '1px solid grey',
    },
    '& > p': {
      margin: 0,
      fontWeight: 'normal',
    },
    '& > button': {
      fontWeight: 'bold',
      letterSpacing: '0.04rem',
      border: 'none',
      height: '25px',
      lineHeight: '.5rem',
      pointerEvents: 'auto',
      textTransform: 'uppercase',
    },
    '& > button.removable': {
      color: PINKISH,
      cursor: 'pointer',
    },
    '& > button.disabled': {
      color: GRAY,
      cursor: 'not-allowed',
    },
  },
});

export interface SwatchSelector {
  swatch: string;
  id: string;
  description: string;
}

export interface ColorHeaderRendererProps extends IHeaderComp {
  $selector: () => SwatchSelector;
  id: string;
  isRemovable: boolean;
  displayRemove?: boolean;
  displayName?: string;
  removeClicked: (id: string, description: string) => void;
}

export const ColorHeaderRenderer: FC<ColorHeaderRendererProps> = (props) => {
  const {
    id: styleId,
    isRemovable,
    displayRemove,
    displayName,
    $selector: getSwatchInfo,
    removeClicked: removeHandler,
  } = props;

  if (isEmpty(displayName) || displayName === 'Group') {
    return null;
  }

  const { description = '', swatch = '' } = getSwatchInfo ? getSwatchInfo() : {};
  const img = getSwatchUrl(swatch);
  const onButtonClick = () => {
    removeHandler(styleId, description);
  };

  return (
    <div className={colorHeaderStyle}>
      <img src={img} />
      <p>{description}</p>
      {displayRemove ? (
        <button
          className={classes(isRemovable ? 'removable' : 'disabled')}
          data-remove-color={styleId}
          disabled={!isRemovable}
          onClick={onButtonClick}
        >
          Remove
        </button>
      ) : null}
    </div>
  );
};

interface ColorHeaderCellRendererProps extends ICellRendererComp {
  $selector: () => SwatchSelector;
  id: string;
  isRemovable: boolean;
  displayRemove?: boolean;
  displayName?: string;
  removeClicked: (id: string) => void;
}

/**
 * @deprecated
 *
 * Please configure a `rowGroupDataIndex` for regular style edit section instead of using `StyleEditMultiSection`
 *
 * This is only present for legacy purposes in `StyleEditMultiSection`
 * where there is still a separate grid for the color headers and data.
 * */
export const ColorHeaderCellRenderer: FC<ColorHeaderCellRendererProps> = (props) => {
  const { id: styleId, isRemovable, displayRemove, $selector: getSwatchInfo, removeClicked: removeHandler } = props;
  const { description = '', swatch = '' } = getSwatchInfo ? getSwatchInfo() : {};
  const img = getSwatchUrl(swatch);
  const onButtonClick = () => {
    removeHandler(styleId);
  };

  return (
    <div className={colorHeaderStyle}>
      <img src={img} />
      <p>{description}</p>
      {displayRemove ? (
        <button
          className={classes(isRemovable ? 'removable' : 'disabled')}
          data-remove-color={styleId}
          disabled={!isRemovable}
          onClick={onButtonClick}
        >
          Remove
        </button>
      ) : null}
    </div>
  );
};
