import React, { Component } from 'react';
import { isNil, isEqual, isEmpty, uniqBy, noop } from 'lodash';
import Axios from 'axios';
import { Overlay } from 'src/common-ui/index';
import container from 'src/ServiceContainer';
import {
  getStyleAttributes,
  getStyleColors,
  getStyleColorsAttr,
  updateStyleItem,
  validateStyleName,
  canRemoveFromAssortment,
} from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.client';
import { BasicPivotItem } from 'src/worker/pivotWorker.types';
import { filterAndSortPivotItems } from 'src/utils/Pivot/Filter';
import { ASSORTMENT } from 'src/utils/Domain/Constants';
import {
  StylePreviewProps,
  StylePreviewState,
  StylePreviewConfig,
  StylePreviewValueProps,
} from 'src/components/StylePreview/StylePreview.types';
import StyleEditSections from 'src/pages/AssortmentBuild/StyleEdit/StyleEditSections/StyleEditSections';
import { StyleOverview, StyleOverviewInputs } from 'src/components/StylePreview/StylePreview.utils';

import styleEditStyles from 'src/pages/AssortmentBuild/StyleEdit/StyleEdit.styles';
import { ContainerPayload } from 'src/components/RightContainer/RightContainer.slice';
import { RightContainerPayloadType } from 'src/components/RightContainer/RightContainer';
import LocalPivotServiceContext from './PivotServiceContext';
import { toast } from 'react-toastify';
import ServiceContainer from 'src/ServiceContainer';
import { makeScopeAndFilterSensitive } from 'src/components/higherOrder/ScopeAndFilterSensitive';
import { AppState } from 'src/store';
import { connect } from 'react-redux';
import { StyleEditSectionsViewDefn } from 'src/services/configuration/codecs/viewdefns/viewdefn';
import { AdornmentContextOptions } from 'src/components/Adornments/Adornments';
import { ContextMenuType } from 'src/components/WorklistContextMenu/WorklistContextMenu';

class StylePreview extends Component<StylePreviewProps, StylePreviewState> {
  canRemoveCancelToken = Axios.CancelToken.source();
  static contextType = LocalPivotServiceContext;
  popoverTarget: React.RefObject<HTMLDivElement> = React.createRef();
  context!: React.ContextType<typeof LocalPivotServiceContext>;

  constructor(props: StylePreviewProps) {
    super(props);

    this.state = {
      isLoading: false,
      validStyleName: true,
      existingColors: [],
      filteredStyleColors: [],
      allStyleColors: [],
      sortedStyleColorIds: [],
      removableStyleColors: [],
      sectionDataLoaded: false,
      sectionIsExpanded: false,
    };
  }

  async componentDidMount() {
    // Skip fetching style information when there is no style. We do this everywhere we call getData
    const dataProm = this.props.previewData.style != null ? this.getData() : Promise.resolve(null);
    Promise.all([this.getConfig(), dataProm]).then((res) => {
      // we do this to allow data to start downloading *with* config instead of delaying.
      const data = res[1];
      return !isNil(data) ? this.processData(...data) : undefined;
    });
  }

  componentDidUpdate(prevProps: StylePreviewProps) {
    const { refreshRequestedAt } = this.props;
    const { refreshRequestedAt: lastRefreshRequestedAt } = prevProps;

    if (prevProps.previewData.id !== this.props.previewData.id && this.props.previewData.style != null) {
      this.getData(true).then((res) => {
        // if a response returns that isn't the current previewId, don't process it
        // this can happen if the user clicks a bunch, multiple promises will get inflight,
        // but we only want to setState the current one
        if (!isNil(res) && res[1].id === this.props.previewData.style) {
          return this.processData(...res);
        }
        return undefined;
      });
    } else if (!isEqual(prevProps.filters, this.props.filters)) {
      const filteredStyleColors = this.filterStyleColors(this.state.allStyleColors);
      const sortedStyleColorIds = filteredStyleColors.map((styleColor) => styleColor.id);
      this.setState({
        filteredStyleColors,
        sortedStyleColorIds,
      });
    }
    if (
      (refreshRequestedAt && !lastRefreshRequestedAt) ||
      (refreshRequestedAt && lastRefreshRequestedAt && refreshRequestedAt > lastRefreshRequestedAt)
    ) {
      // This exists to detect planRefreshes and dump all the sections and data when a plan refresh is requested
      this.refreshData();
    }
  }

  componentWillUnmount() {
    this.context.clearPivotCache();
  }
  /**
   * Clear the local pivot cache when scope or filters are cleared
   */
  onRefetchData = () => {
    this.context.clearPivotCache();
  };

  // StylePreview is responsible for retrieving it's own config
  // because it can be used as a standalone component (i.e. WorklistViewContainer)
  // and the parent component will not have loaded the config specific to this view
  getConfig = async () => {
    const { sectionsViewDefns, onError } = this.props;
    if (isNil(sectionsViewDefns)) {
      return;
    }

    try {
      const configs = await container.tenantConfigClient.getTenantViewDefns<StylePreviewConfig>({
        defnIds: sectionsViewDefns,
        appName: ASSORTMENT,
        validationSchemas: [StyleEditSectionsViewDefn],
      });

      const [sectionsConfig] = configs;
      this.setState({
        config: sectionsConfig,
      });
    } catch (error) {
      if (!isNil(onError)) {
        onError(error);
      }
    }
  };

  handleCanRemove = async (uniqueStyleColors: BasicPivotItem[]) => {
    canRemoveFromAssortment(uniqueStyleColors.map((item) => item.id)).then((removableStyleColors) => {
      this.setState({
        removableStyleColors,
      });
    });
  };

  processData = async (
    styleColorAttrs: BasicPivotItem[],
    styleAttrs: BasicPivotItem,
    styleColors: BasicPivotItem[]
  ) => {
    // const styleColors = style ? await getStyleColors(style) : await getStyleColors(style);
    const uniqueStyleColors = uniqBy(styleColors, (item) => item.id);
    const filteredStyleColors = this.filterStyleColors(uniqueStyleColors);
    const sortedStyleColorIds = filteredStyleColors.map((sc) => sc.id);
    this.setState({
      isLoading: false,
      existingColors: styleColorAttrs,
      data: styleAttrs,
      allStyleColors: uniqueStyleColors,
      filteredStyleColors,
      sortedStyleColorIds,
    });

    if (this.props.readOnlyView !== true) {
      this.handleCanRemove(uniqueStyleColors);
    }
  };

  getData = async (override?: boolean) => {
    const { id, style } = this.props.previewData;
    const { isLoading } = this.state;
    let fetchId = id;

    if (isEmpty(id) || (isLoading && !override)) {
      return;
    }
    if (!isNil(style)) {
      fetchId = style;
    }

    this.setState({
      isLoading: true,
    });

    return Promise.all([
      getStyleColorsAttr(fetchId, true),
      getStyleAttributes(fetchId),
      getStyleColors(this.context, fetchId),
    ]);
  };

  filterStyleColors(data: BasicPivotItem[]) {
    const { filters, searchKeys } = this.props;
    const { altSearch, altFlowStatus, sortBy } = filters;
    return filterAndSortPivotItems(altSearch, sortBy, searchKeys, altFlowStatus, data);
  }

  validateName = (requestedName: string) => {
    // skips validating name against itself
    // unless the name was previously invalid and not updated in state.data
    if (!isNil(this.state.data) && requestedName === this.state.data.name && this.state.validStyleName) {
      return;
    }

    validateStyleName(requestedName).then((isValid: boolean) => {
      if (isValid) {
        const { data } = this.state;

        if (!isNil(data)) {
          const origStyleId = data.id;
          const newData = {
            ...data,
            name: requestedName,
          };

          this.setState(
            {
              validStyleName: isValid,
              data: newData,
            },
            () => {
              this.saveData(origStyleId, 'name', requestedName);
              this.refreshData(true);
            }
          );
        }
      } else {
        this.setState({
          validStyleName: isValid,
        });
      }
    });
  };

  updateDescription = (description: string) => {
    const { data } = this.state;

    if (!isNil(data)) {
      if (data.description === description) {
        return;
      }

      const origStyleId = data.id;
      const newData = {
        ...data,
        description,
      };

      this.setState(
        {
          data: newData,
        },
        () => {
          this.saveData(origStyleId, 'description', description);
          this.refreshData(true);
        }
      );
    }
  };

  saveData = (styleId: string, propToUpdate: string, propValue: string) => {
    const reqData = {
      id: styleId,
      [propToUpdate]: propValue,
    };

    updateStyleItem(reqData)
      .then((resp) => {
        this.context.clearPivotCache();
        const { onStyleItemEdit } = this.props;

        if (resp.data.success && onStyleItemEdit) {
          onStyleItemEdit(styleId, propToUpdate, propValue);
        }
      })
      .catch((e) => {
        toast.error('An error occured updating your style');
        ServiceContainer.loggingService.error('An error occured updating the style edit in styleeditsection', e.stack);
      });
  };

  refreshData = async (refreshCompanionView = false) => {
    const { onRefreshView } = this.props;
    this.context.clearPivotCache();
    if (refreshCompanionView && onRefreshView) {
      onRefreshView();
      return;
    }
    if (this.props.previewData.style != null) {
      this.getData().then((res) => {
        return !isNil(res) ? this.processData(...res) : undefined;
      });
    }
  };

  handleSectionChange = (isExpanded: boolean, sectionDataLoaded: boolean) => {
    this.setState({
      sectionIsExpanded: isExpanded,
      sectionDataLoaded,
    });
  };

  getContextOptions = () => {
    const { previewData, onShowStylePane } = this.props;
    const containerPayload: ContainerPayload = {
      type: RightContainerPayloadType.Assortment,
      id: previewData.id,
      parentId: previewData.style,
      isAssortmentBuild: true,
    };
    const openStylePane = !isNil(onShowStylePane) ? onShowStylePane.bind(null, containerPayload) : noop;
    const contextOptions: AdornmentContextOptions = {
      type: ContextMenuType.card,
      parentIdentityField: 'style',
      onOpenStylePane: openStylePane,
      nodeData: previewData,
    };
    return contextOptions;
  };

  render() {
    const {
      previewData,
      ignoreOverviewBackground,
      renderSections,
      selectedStyleColorId,
      adornments,
      filterSwatches,
      onSwatchClick,
    } = this.props;
    const {
      isLoading,
      config,
      data,
      allStyleColors,
      sortedStyleColorIds,
      filteredStyleColors,
      existingColors,
      removableStyleColors,
      sectionIsExpanded,
      validStyleName,
    } = this.state;
    if (isLoading) {
      return (
        <div className={styleEditStyles.editViewContainer}>
          <Overlay type="loading" visible={true} qaKey={'StylePreviewOverlay'} />
        </div>
      );
    }

    // sets the width of the container to be big enough to contain the colors and the add color button
    // if there are few colors (~ less than 3, depending on screen width)
    // the minWidth set below is 100% and overrides this behavior
    const filteredStylesLength = filteredStyleColors.length;
    const extendWidthByNumber = filteredStylesLength - Math.round((window.innerWidth - 850) / 200);
    // 200px is the column width, 60px is the margin the add color button takes up
    const calculatedWidth = `calc(100% + (200px * ${extendWidthByNumber}) + 60px)`;
    const dynamicWidth = sectionIsExpanded && filteredStylesLength ? calculatedWidth : '100%';
    const adornmentContextOptions = this.getContextOptions();

    return (
      <div
        className={styleEditStyles.editViewContainer}
        style={{ width: renderSections ? dynamicWidth : '100%', minWidth: '100%' }}
      >
        <StyleOverviewInputs
          config={config}
          data={data}
          previewData={{
            ...previewData,
            id: previewData.id,
          }}
          validStyleName={validStyleName}
          ignoreOverviewBackground={ignoreOverviewBackground}
          renderSections={renderSections}
          onValidateName={this.validateName}
          onUpdateDescription={this.updateDescription}
        />
        <div className={styleEditStyles.styleEditOverviewImages} data-qa="StyleEditStylePreview">
          <StyleOverview
            allStyleColors={allStyleColors}
            filteredStyleColors={filterSwatches ? filterSwatches(filteredStyleColors) : filteredStyleColors}
            previewData={previewData}
            selectedStyleColorId={selectedStyleColorId}
            adornments={adornments}
            adornmentContextOptions={adornmentContextOptions}
            onChangeColor={onSwatchClick}
          />
        </div>
        {renderSections && !isNil(config) && (
          <StyleEditSections
            sectionsConfig={config.sections}
            styleData={data}
            allStyleColors={allStyleColors}
            filteredStyleColors={filteredStyleColors}
            existingStyleColors={existingColors}
            removableStyleColors={removableStyleColors}
            sortedStyleColorIds={sortedStyleColorIds}
            onRefresh={this.refreshData}
            onSectionChange={this.handleSectionChange}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = (state: AppState): StylePreviewValueProps => {
  return {
    refreshRequestedAt: state.planTracker.refreshRequestedAt,
  };
};
export default connect(mapStateToProps)(makeScopeAndFilterSensitive(StylePreview));
