import React, { useCallback, useEffect, useState } from 'react';
import { CustomCellRendererProps } from '@ag-grid-community/react';
import { RowEvent } from '@ag-grid-community/core';

export const GroupRowCellRenderer = ({ node, value }: CustomCellRendererProps) => {
  const [expanded, setExpanded] = useState(node.expanded);

  useEffect(() => {
    const expandListener = (event: RowEvent) => setExpanded(event.node.expanded);

    node.addEventListener('expandedChanged', expandListener);

    return () => {
      node.removeEventListener('expandedChanged', expandListener);
    };
  }, [node]);

  const onClick = useCallback(() => node.setExpanded(!node.expanded), [node]);

  return (
    <div
      style={{
        display: 'flex',
        marginLeft: '0.5rem',
        cursor: 'pointer',
        fontSize: '1.1rem',
        fontWeight: 500,
      }}
      onClick={onClick}
    >
      <span
        className={`ag-icon ag-icon-tree-${expanded ? 'open' : 'closed'}`}
        style={{ marginRight: '0.5rem' }}
        role="presentation"
      />
      <span>{value}</span>
    </div>
  );
};
